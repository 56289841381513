define("@yogeshsahu/fleetops-data/serializers/person-in-charge", ["exports", "@fleetbase/ember-core/serializers/application", "@ember-data/serializer/rest", "@ember/object", "@ember/array"], function (_exports, _application, _rest, _object, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PersonInChargeSerializer extends _application.default.extend(_rest.EmbeddedRecordsMixin) {
    /**
     * Embedded relationship attributes
     *
     * @var {Object}
     */
    get attrs() {
      return {
        user: {
          embedded: 'always'
        },
        fleets: {
          embedded: 'always'
        },
        vendor: {
          embedded: 'always'
        },
        vehicle: {
          embedded: 'always'
        },
        devices: {
          embedded: 'always'
        },
        current_job: {
          embedded: 'always'
        },
        jobs: {
          embedded: 'always'
        }
      };
    }
    serializeBelongsTo(snapshot, json, relationship) {
      let key = relationship.key;
      if (key === 'fleets' || key === 'current_job' || key === 'user' || key === 'vendor') {
        return;
      }
      if (key === 'vehicle' && (0, _array.isArray)(json[key])) {
        json[`${key}_uuid`] = (0, _object.get)(json, `${key}.uuid`);
        return;
      }
      super.serializeBelongsTo(...arguments);
    }
    serializeHasMany(snapshot, json, relationship) {
      let key = relationship.key;
      if (key === 'jobs' || key === 'orders' || key == 'fleets') {
        return;
      }
      super.serializeHasMany(...arguments);
    }
  }
  _exports.default = PersonInChargeSerializer;
});