define("@yogeshsahu/fleetops-data/models/vehicle", ["exports", "@ember-data/model", "@ember/object", "date-fns", "@ember/application", "@fleetbase/ember-core/utils/is-relation-missing", "ember-get-config"], function (_exports, _model, _object, _dateFns, _application, _isRelationMissing, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let VehicleModel = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('boolean'), _dec7 = (0, _model.belongsTo)('driver', {
    async: false
  }), _dec8 = (0, _model.belongsTo)('vendor', {
    async: false
  }), _dec9 = (0, _model.hasMany)('vehicle-device', {
    async: false
  }), _dec10 = (0, _model.attr)('string', {
    defaultValue: (0, _object.get)(_emberGetConfig.default, 'defaultValues.vehicleImage')
  }), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string', {
    defaultValue: (0, _object.get)(_emberGetConfig.default, 'defaultValues.vehicleAvatar')
  }), _dec17 = (0, _model.attr)('point'), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _model.attr)('string'), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.attr)('string'), _dec22 = (0, _model.attr)('string'), _dec23 = (0, _model.attr)('string'), _dec24 = (0, _model.attr)('string'), _dec25 = (0, _model.attr)('raw'), _dec26 = (0, _model.attr)('raw'), _dec27 = (0, _model.attr)('raw'), _dec28 = (0, _model.attr)('raw'), _dec29 = (0, _model.attr)('string'), _dec30 = (0, _model.attr)('string'), _dec31 = (0, _model.attr)('date'), _dec32 = (0, _model.attr)('date'), _dec33 = (0, _model.attr)('date'), _dec34 = (0, _object.computed)('year', 'make', 'model', 'trim', 'plate_number', 'internal_id'), _dec35 = (0, _object.computed)('updated_at'), _dec36 = (0, _object.computed)('updated_at'), _dec37 = (0, _object.computed)('updated_at'), _dec38 = (0, _object.computed)('created_at'), _dec39 = (0, _object.computed)('created_at'), _dec40 = (0, _object.computed)('created_at'), _class = class VehicleModel extends _model.default {
    constructor(...args) {
      super(...args);
      /** @ids */
      _initializerDefineProperty(this, "uuid", _descriptor, this);
      _initializerDefineProperty(this, "public_id", _descriptor2, this);
      _initializerDefineProperty(this, "company_uuid", _descriptor3, this);
      _initializerDefineProperty(this, "photo_uuid", _descriptor4, this);
      _initializerDefineProperty(this, "vendor_uuid", _descriptor5, this);
      _initializerDefineProperty(this, "online", _descriptor6, this);
      /** @relationships */
      _initializerDefineProperty(this, "driver", _descriptor7, this);
      _initializerDefineProperty(this, "vendor", _descriptor8, this);
      _initializerDefineProperty(this, "devices", _descriptor9, this);
      /** @attributes */
      _initializerDefineProperty(this, "photo_url", _descriptor10, this);
      _initializerDefineProperty(this, "driver_name", _descriptor11, this);
      _initializerDefineProperty(this, "vendor_name", _descriptor12, this);
      _initializerDefineProperty(this, "display_name", _descriptor13, this);
      _initializerDefineProperty(this, "gps", _descriptor14, this);
      _initializerDefineProperty(this, "erp", _descriptor15, this);
      _initializerDefineProperty(this, "avatar_url", _descriptor16, this);
      _initializerDefineProperty(this, "location", _descriptor17, this);
      _initializerDefineProperty(this, "make", _descriptor18, this);
      _initializerDefineProperty(this, "model", _descriptor19, this);
      _initializerDefineProperty(this, "year", _descriptor20, this);
      _initializerDefineProperty(this, "trim", _descriptor21, this);
      _initializerDefineProperty(this, "type", _descriptor22, this);
      _initializerDefineProperty(this, "plate_number", _descriptor23, this);
      _initializerDefineProperty(this, "vin", _descriptor24, this);
      _initializerDefineProperty(this, "vin_data", _descriptor25, this);
      _initializerDefineProperty(this, "model_data", _descriptor26, this);
      _initializerDefineProperty(this, "telematics", _descriptor27, this);
      _initializerDefineProperty(this, "meta", _descriptor28, this);
      _initializerDefineProperty(this, "status", _descriptor29, this);
      _initializerDefineProperty(this, "slug", _descriptor30, this);
      /** @dates */
      _initializerDefineProperty(this, "deleted_at", _descriptor31, this);
      _initializerDefineProperty(this, "created_at", _descriptor32, this);
      _initializerDefineProperty(this, "updated_at", _descriptor33, this);
    }
    /** @computed */
    get displayName() {
      const nameSegments = [this.year, this.make, this.model, this.trim, this.plate_number, this.internal_id];
      return nameSegments.filter(Boolean).join(' ').trim();
    }
    get updatedAgo() {
      if (!(0, _dateFns.isValid)(this.updated_at)) {
        return null;
      }
      return (0, _dateFns.formatDistanceToNow)(this.updated_at);
    }
    get updatedAt() {
      if (!(0, _dateFns.isValid)(this.updated_at)) {
        return null;
      }
      return (0, _dateFns.format)(this.updated_at, 'PPP p');
    }
    get updatedAtShort() {
      if (!(0, _dateFns.isValid)(this.updated_at)) {
        return null;
      }
      return (0, _dateFns.format)(this.updated_at, 'PP');
    }
    get createdAgo() {
      if (!(0, _dateFns.isValid)(this.created_at)) {
        return null;
      }
      return (0, _dateFns.formatDistanceToNow)(this.created_at);
    }
    get createdAt() {
      if (!(0, _dateFns.isValid)(this.created_at)) {
        return null;
      }
      return (0, _dateFns.format)(this.created_at, 'PPP p');
    }
    get createdAtShort() {
      if (!(0, _dateFns.isValid)(this.created_at)) {
        return null;
      }
      return (0, _dateFns.format)(this.created_at, 'PP');
    }

    /** @methods */
    loadDriver() {
      const owner = (0, _application.getOwner)(this);
      const store = owner.lookup('service:store');
      return new Promise(resolve => {
        if ((0, _isRelationMissing.default)(this, 'driver')) {
          return store.findRecord('driver', this.driver_uuid).then(driver => {
            this.driver = driver;
            resolve(driver);
          }).catch(() => {
            resolve(null);
          });
        }
        resolve(this.driver);
      });
    }
    loadDevices() {
      const owner = (0, _application.getOwner)(this);
      const store = owner.lookup('service:store');
      return new Promise((resolve, reject) => {
        return store.findRecord('vehicle-device', {
          vehicle_uuid: this.id
        }).then(devices => {
          this.vehicle_devices = devices;
          resolve(devices);
        }).catch(reject);
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uuid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "public_id", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "company_uuid", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "photo_uuid", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "vendor_uuid", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "online", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "driver", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "vendor", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "devices", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "photo_url", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "driver_name", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "vendor_name", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "display_name", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "gps", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "erp", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "avatar_url", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "location", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "make", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "year", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "trim", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "plate_number", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "vin", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "vin_data", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "model_data", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "telematics", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "meta", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "slug", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "deleted_at", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "created_at", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "updated_at", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "displayName", [_dec34], Object.getOwnPropertyDescriptor(_class.prototype, "displayName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAgo", [_dec35], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec36], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatedAtShort", [_dec37], Object.getOwnPropertyDescriptor(_class.prototype, "updatedAtShort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAgo", [_dec38], Object.getOwnPropertyDescriptor(_class.prototype, "createdAgo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec39], Object.getOwnPropertyDescriptor(_class.prototype, "createdAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createdAtShort", [_dec40], Object.getOwnPropertyDescriptor(_class.prototype, "createdAtShort"), _class.prototype), _class);
});